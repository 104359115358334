@import "index.css";
@import "responsive.css";
@import "theme.css";
@import "typography.css";
@import "ToastStyles.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
