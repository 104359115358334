/* :root {
    --primary-color: #f0defc;
    --secondary-color: #9900FF;
    --font-color: #424242;
    --bg-color: #ebebeb;
    --heading-color: #292922;
    --sidenavbar-color: #ffffff;
    --sidenavbaricon-color: #100901;
    --sidenavbarbottomborder-color: #e9e9e9;
    --type2-primary-color: #f0defc;
    --type2-secondary-color: #9900FF;
  }
  
  [data-theme=dark] {
    --primary-color: #9900FF;
    --secondary-color: #f0defc;
    --font-color: #e1e1ff;
    --bg-color: #161625;
    --heading-color: #818cab;
    --sidenavbar-color: #100901;
    --sidenavbaricon-color: #ffffff;
    --sidenavbarbottomborder-color: #292929;
    --type2-primary-color: #9900FF;
    --type2-secondary-color: #f0defc;
  } */

/* Theme Font Sizes */

.theme-XS-text {
  font-size: 10px;
}
.theme-SM-text {
  font-size: 12px;
}
.theme-M-text {
  font-size: 14px;
}
.theme-L-text {
  font-size: 16px;
}

/* Font Sizes */
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-30 {
  font-size: 30px;
}
.font-36 {
  font-size: 36px;
}
.font-48 {
  font-size: 48px;
}
.font-60 {
  font-size: 60px;
}

/* Font Weights */
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}

/* Theme Font Sizes Ends */

/* -----------------------------------------------------Spacing------------------------------------------- */

/* Vertical Spacing */

.space-t-10 {
  margin-top: 10px;
}
.space-t-20 {
  margin-top: 20px;
}
.space-t-30 {
  margin-top: 30px;
}
.space-t-40 {
  margin-top: 40px;
}
.space-t-50 {
  margin-top: 50px;
}
.space-t-60 {
  margin-top: 60px;
}
.space-t-70 {
  margin-top: 70px;
}
.space-t-80 {
  margin-top: 80px;
}
.space-t-90 {
  margin-top: 90px;
}
.space-t-100 {
  margin-top: 100px;
}
.space-t-110 {
  margin-top: 110px;
}
.space-t-120 {
  margin-top: 120px;
}
.space-t-130 {
  margin-top: 130px;
}
.space-t-140 {
  margin-top: 140px;
}
.space-t-150 {
  margin-top: 150px;
}

/* Add more classes for different values as needed */

.space-b-10 {
  margin-bottom: 10px;
}
.space-b-20 {
  margin-bottom: 20px;
}
.space-b-30 {
  margin-bottom: 30px;
}
.space-b-40 {
  margin-bottom: 40px;
}
.space-b-50 {
  margin-bottom: 50px;
}
.space-b-60 {
  margin-bottom: 60px;
}
.space-b-70 {
  margin-bottom: 70px;
}
.space-b-80 {
  margin-bottom: 80px;
}
.space-b-90 {
  margin-bottom: 90px;
}
.space-b-100 {
  margin-bottom: 100px;
}
.space-b-110 {
  margin-bottom: 110px;
}
.space-b-120 {
  margin-bottom: 120px;
}
.space-b-130 {
  margin-bottom: 130px;
}
.space-b-140 {
  margin-bottom: 140px;
}
.space-b-150 {
  margin-bottom: 150px;
}

/* Add more classes for different values as needed */

/* Horizontal Spacing */
.space-l-10 {
  margin-left: 10px;
}
.space-l-20 {
  margin-left: 20px;
}
.space-l-30 {
  margin-left: 30px;
}
.space-l-40 {
  margin-left: 40px;
}
.space-l-50 {
  margin-left: 50px;
}
.space-l-60 {
  margin-left: 60px;
}
.space-l-70 {
  margin-left: 70px;
}
.space-l-80 {
  margin-left: 80px;
}
.space-l-90 {
  margin-left: 90px;
}
.space-l-100 {
  margin-left: 100px;
}
.space-l-110 {
  margin-left: 110px;
}
.space-l-120 {
  margin-left: 120px;
}
.space-l-130 {
  margin-left: 130px;
}
.space-l-140 {
  margin-left: 140px;
}
.space-l-150 {
  margin-left: 150px;
}

/* Add more classes for different values as needed */

.space-r-10 {
  margin-right: 10px;
}
.space-r-20 {
  margin-right: 20px;
}
.space-r-30 {
  margin-right: 30px;
}
.space-r-40 {
  margin-right: 40px;
}
.space-r-50 {
  margin-right: 50px;
}
.space-r-60 {
  margin-right: 60px;
}
.space-r-70 {
  margin-right: 70px;
}
.space-r-80 {
  margin-right: 80px;
}
.space-r-90 {
  margin-right: 90px;
}
.space-r-100 {
  margin-right: 100px;
}
.space-r-110 {
  margin-right: 110px;
}
.space-r-120 {
  margin-right: 120px;
}
.space-r-130 {
  margin-right: 130px;
}
.space-r-140 {
  margin-right: 140px;
}
.space-r-150 {
  margin-right: 150px;
}

/* Vertical Spacing Multiples 0f 4 */

.space-t-4 {
  margin-top: 4px;
}
.space-t-8 {
  margin-top: 8px;
}
.space-t-12 {
  margin-top: 12px;
}
.space-t-16 {
  margin-top: 16px;
}
.space-t-20 {
  margin-top: 20px;
}
.space-t-24 {
  margin-top: 24px;
}
.space-t-28 {
  margin-top: 28px;
}
.space-t-32 {
  margin-top: 32px;
}
.space-t-36 {
  margin-top: 36px;
}
.space-t-40 {
  margin-top: 40px;
}
.space-t-44 {
  margin-top: 44px;
}
.space-t-48 {
  margin-top: 48px;
}
.space-t-52 {
  margin-top: 52px;
}
.space-t-56 {
  margin-top: 56px;
}
.space-t-60 {
  margin-top: 60px;
}

.space-b-4 {
  margin-bottom: 4px;
}
.space-b-8 {
  margin-bottom: 8px;
}
.space-b-12 {
  margin-bottom: 12px;
}
.space-b-16 {
  margin-bottom: 16px;
}
.space-b-20 {
  margin-bottom: 20px;
}
.space-b-24 {
  margin-bottom: 24px;
}
.space-b-28 {
  margin-bottom: 28px;
}
.space-b-32 {
  margin-bottom: 32px;
}
.space-b-36 {
  margin-bottom: 36px;
}
.space-b-40 {
  margin-bottom: 40px;
}
.space-b-44 {
  margin-bottom: 44px;
}
.space-b-48 {
  margin-bottom: 48px;
}
.space-b-52 {
  margin-bottom: 52px;
}
.space-b-56 {
  margin-bottom: 56px;
}
.space-b-60 {
  margin-bottom: 60px;
}

/* Horizontal Spacing Multiples 0f 4 */
.space-l-4 {
  margin-left: 4px;
}
.space-l-8 {
  margin-left: 8px;
}
.space-l-12 {
  margin-left: 12px;
}
.space-l-16 {
  margin-left: 16px;
}
.space-l-20 {
  margin-left: 20px;
}
.space-l-24 {
  margin-left: 24px;
}
.space-l-28 {
  margin-left: 28px;
}
.space-l-32 {
  margin-left: 32px;
}
.space-l-36 {
  margin-left: 36px;
}
.space-l-40 {
  margin-left: 40px;
}
.space-l-44 {
  margin-left: 44px;
}
.space-l-48 {
  margin-left: 48px;
}
.space-l-52 {
  margin-left: 52px;
}
.space-l-56 {
  margin-left: 56px;
}
.space-l-60 {
  margin-left: 60px;
}

.space-r-4 {
  margin-right: 4px;
}
.space-r-8 {
  margin-right: 8px;
}
.space-r-12 {
  margin-right: 12px;
}
.space-r-16 {
  margin-right: 16px;
}
.space-r-20 {
  margin-right: 20px;
}
.space-r-24 {
  margin-right: 24px;
}
.space-r-28 {
  margin-right: 28px;
}
.space-r-32 {
  margin-right: 32px;
}
.space-r-36 {
  margin-right: 36px;
}
.space-r-40 {
  margin-right: 40px;
}
.space-r-44 {
  margin-right: 44px;
}
.space-r-48 {
  margin-right: 48px;
}
.space-r-52 {
  margin-right: 52px;
}
.space-r-56 {
  margin-right: 56px;
}
.space-r-60 {
  margin-right: 60px;
}

/* Add more classes for different values as needed */

/* Padding Multiples 0f 4 */
.pad-10 {
  padding: 10px;
}
.pad-20 {
  padding: 20px;
}
.pad-30 {
  padding: 30px;
}
.pad-40 {
  padding: 40px;
}
.pad-50 {
  padding: 50px;
}
.pad-60 {
  padding: 60px;
}
.pad-70 {
  padding: 70px;
}
.pad-80 {
  padding: 80px;
}
.pad-90 {
  padding: 90px;
}
.pad-100 {
  padding: 100px;
}
.pad-110 {
  padding: 110px;
}
.pad-120 {
  padding: 120px;
}
.pad-130 {
  padding: 130px;
}
.pad-140 {
  padding: 140px;
}
.pad-150 {
  padding: 150px;
}

/* Padding Top Multiples 0f 4 */
.pad-t-10 {
  padding-top: 10px;
}
.pad-t-20 {
  padding-top: 20px;
}
.pad-t-30 {
  padding-top: 30px;
}
.pad-t-40 {
  padding-top: 40px;
}
.pad-t-50 {
  padding-top: 50px;
}
.pad-t-60 {
  padding-top: 60px;
}
.pad-t-70 {
  padding-top: 70px;
}
.pad-t-80 {
  padding-top: 80px;
}
.pad-t-90 {
  padding-top: 90px;
}
.pad-t-100 {
  padding-top: 100px;
}
.pad-t-110 {
  padding-top: 110px;
}
.pad-t-120 {
  padding-top: 120px;
}
.pad-t-130 {
  padding-top: 130px;
}
.pad-t-140 {
  padding-top: 140px;
}
.pad-t-150 {
  padding-top: 150px;
}

/* Padding Bottom Multiples 0f 4*/
.pad-b-10 {
  padding-bottom: 10px;
}
.pad-b-20 {
  padding-bottom: 20px;
}
.pad-b-30 {
  padding-bottom: 30px;
}
.pad-b-40 {
  padding-bottom: 40px;
}
.pad-b-50 {
  padding-bottom: 50px;
}
.pad-b-60 {
  padding-bottom: 60px;
}
.pad-b-70 {
  padding-bottom: 70px;
}
.pad-b-80 {
  padding-bottom: 80px;
}
.pad-b-90 {
  padding-bottom: 90px;
}
.pad-b-100 {
  padding-bottom: 100px;
}
.pad-b-110 {
  padding-bottom: 110px;
}
.pad-b-120 {
  padding-bottom: 120px;
}
.pad-b-130 {
  padding-bottom: 130px;
}
.pad-b-140 {
  padding-bottom: 140px;
}
.pad-b-150 {
  padding-bottom: 150px;
}

/* Padding Left Multiples 0f 4*/
.pad-l-10 {
  padding-left: 10px;
}
.pad-l-20 {
  padding-left: 20px;
}
.pad-l-30 {
  padding-left: 30px;
}
.pad-l-40 {
  padding-left: 40px;
}
.pad-l-50 {
  padding-left: 50px;
}
.pad-l-60 {
  padding-left: 60px;
}
.pad-l-70 {
  padding-left: 70px;
}
.pad-l-80 {
  padding-left: 80px;
}
.pad-l-90 {
  padding-left: 90px;
}
.pad-l-100 {
  padding-left: 100px;
}
.pad-l-110 {
  padding-left: 110px;
}
.pad-l-120 {
  padding-left: 120px;
}
.pad-l-130 {
  padding-left: 130px;
}
.pad-l-140 {
  padding-left: 140px;
}
.pad-l-150 {
  padding-left: 150px;
}

/* Padding Right Multiples 0f 4*/
.pad-r-10 {
  padding-right: 10px;
}
.pad-r-20 {
  padding-right: 20px;
}
.pad-r-30 {
  padding-right: 30px;
}
.pad-r-40 {
  padding-right: 40px;
}
.pad-r-50 {
  padding-right: 50px;
}
.pad-r-60 {
  padding-right: 60px;
}
.pad-r-70 {
  padding-right: 70px;
}
.pad-r-80 {
  padding-right: 80px;
}
.pad-r-90 {
  padding-right: 90px;
}
.pad-r-100 {
  padding-right: 100px;
}
.pad-r-110 {
  padding-right: 110px;
}
.pad-r-120 {
  padding-right: 120px;
}
.pad-r-130 {
  padding-right: 130px;
}
.pad-r-140 {
  padding-right: 140px;
}
.pad-r-150 {
  padding-right: 150px;
}

/* Padding Multiples 0f 4*/
.pad-4 {
  padding: 4px;
}
.pad-8 {
  padding: 8px;
}
.pad-12 {
  padding: 12px;
}
.pad-16 {
  padding: 16px;
}
.pad-20 {
  padding: 20px;
}
.pad-24 {
  padding: 24px;
}
.pad-28 {
  padding: 28px;
}
.pad-32 {
  padding: 32px;
}
.pad-36 {
  padding: 36px;
}
.pad-40 {
  padding: 40px;
}
.pad-44 {
  padding: 44px;
}
.pad-48 {
  padding: 48px;
}
.pad-52 {
  padding: 52px;
}
.pad-56 {
  padding: 56px;
}
.pad-60 {
  padding: 60px;
}

/* Padding Top Multiples 0f 4*/
.pad-t-4 {
  padding-top: 4px;
}
.pad-t-8 {
  padding-top: 8px;
}
.pad-t-12 {
  padding-top: 12px;
}
.pad-t-16 {
  padding-top: 16px;
}
.pad-t-20 {
  padding-top: 20px;
}
.pad-t-24 {
  padding-top: 24px;
}
.pad-t-28 {
  padding-top: 28px;
}
.pad-t-32 {
  padding-top: 32px;
}
.pad-t-36 {
  padding-top: 36px;
}
.pad-t-40 {
  padding-top: 40px;
}
.pad-t-44 {
  padding-top: 44px;
}
.pad-t-48 {
  padding-top: 48px;
}
.pad-t-52 {
  padding-top: 52px;
}
.pad-t-56 {
  padding-top: 56px;
}
.pad-t-60 {
  padding-top: 60px;
}

/* Padding Bottom Multiples 0f 4*/
.pad-b-4 {
  padding-bottom: 4px;
}
.pad-b-8 {
  padding-bottom: 8px;
}
.pad-b-12 {
  padding-bottom: 12px;
}
.pad-b-16 {
  padding-bottom: 16px;
}
.pad-b-20 {
  padding-bottom: 20px;
}
.pad-b-24 {
  padding-bottom: 24px;
}
.pad-b-28 {
  padding-bottom: 28px;
}
.pad-b-32 {
  padding-bottom: 32px;
}
.pad-b-36 {
  padding-bottom: 36px;
}
.pad-b-40 {
  padding-bottom: 40px;
}
.pad-b-44 {
  padding-bottom: 44px;
}
.pad-b-48 {
  padding-bottom: 48px;
}
.pad-b-52 {
  padding-bottom: 52px;
}
.pad-b-56 {
  padding-bottom: 56px;
}
.pad-b-60 {
  padding-bottom: 60px;
}

/* Padding Left Multiples 0f 4*/
.pad-l-4 {
  padding-left: 4px;
}
.pad-l-8 {
  padding-left: 8px;
}
.pad-l-12 {
  padding-left: 12px;
}
.pad-l-16 {
  padding-left: 16px;
}
.pad-l-20 {
  padding-left: 20px;
}
.pad-l-24 {
  padding-left: 24px;
}
.pad-l-28 {
  padding-left: 28px;
}
.pad-l-32 {
  padding-left: 32px;
}
.pad-l-36 {
  padding-left: 36px;
}
.pad-l-40 {
  padding-left: 40px;
}
.pad-l-44 {
  padding-left: 44px;
}
.pad-l-48 {
  padding-left: 48px;
}
.pad-l-52 {
  padding-left: 52px;
}
.pad-l-56 {
  padding-left: 56px;
}
.pad-l-60 {
  padding-left: 60px;
}

/* Padding Right Multiples 0f 4*/
.pad-r-4 {
  padding-right: 4px;
}
.pad-r-8 {
  padding-right: 8px;
}
.pad-r-12 {
  padding-right: 12px;
}
.pad-r-16 {
  padding-right: 16px;
}
.pad-r-20 {
  padding-right: 20px;
}
.pad-r-24 {
  padding-right: 24px;
}
.pad-r-28 {
  padding-right: 28px;
}
.pad-r-32 {
  padding-right: 32px;
}
.pad-r-36 {
  padding-right: 36px;
}
.pad-r-40 {
  padding-right: 40px;
}
.pad-r-44 {
  padding-right: 44px;
}
.pad-r-48 {
  padding-right: 48px;
}
.pad-r-52 {
  padding-right: 52px;
}
.pad-r-56 {
  padding-right: 56px;
}
.pad-r-60 {
  padding-right: 60px;
}

/* Main colors */
.text-color-1{
  color : #05E235;
}

.text-color-2{
  color : #B3B3B3;
}

.text-color-3{
  color : #606060;
}

.text-color-4{
  color : #9900FF;
}

.text-color-5{
  color : #2A2E34;
}

.text-color-6{
  color : #F0DEFC;
}

.text-color-7{
  color : #EBC004;
}

.text-color-8{
  color : #D4D4D4;
}

.text-color-9{
  color : #FF6C6C;
}

.text-color-10{
  color : #5357B6;
}

.text-color-10{
  color: #69CCFD;
}

.text-color-11{
  color: #FF4A4A;
}

.text-color-12{
  color: #08671C;
}

.text-color-13{
  color: #2FB5F7;
}

.text-color-14{
  color: #D9D9D9;
}

.text-color-15{
  color: #dca7ff;
}

.bg-color-1{
  background-color : #05E235;
}

.bg-color-2{
  background-color : #B3B3B3;
}

.bg-color-3{
  background-color : #606060;
}

.bg-color-4{
  background-color : #9900FF;
}

.bg-color-5{
  background-color : #2A2E34;
}

.bg-color-6{
  background-color : #F0DEFC;
}

.bg-color-7{
  background-color : #EBC004;
}

.bg-color-8{
  background-color : #D4D4D4;
}

.bg-color-9{
  background-color : #F5F4F4;
}

.bg-color-10{
  background-color: #F0DEFC;
}

.bg-color-11{
  background-color: #DBDBDB;
}

.bg-color-12{
  background-color: #FFE3E3;
}

.bg-color-13{
  background-color: #69CCFD;
}

.bg-color-14{
  background-color: #FFE3E3;
}

.bg-color-15{
  background-color: #FF4A4A;
}

.bg-color-16{
  background-color: #D2EDFA;
}

.bg-color-17{
  background-color: #E9FFEF;
}

.bg-color-18{
  background-color: #2682B5;
}

.bg-color-19{
  background-color: #EAFFDE;
}



/* button */
.btnn{
  height : 24px;
  width : 24px;
  border-radius : 4px;
  text-align : center;
  border : none;
  padding-left : 5px;
}

::-webkit-scrollbar {
  width: 25px;
}

::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  border-radius : 0px;
}