@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("./responsive.css");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primary-color: #f0defc;
  --secondary-color: #9900ff;
  --dark-theme-font-color: #606060;
  --table-display-heading-font-color: #b5b7c0;
  --table-display-body-font-color: #606060;
  --font-color: #ababab;
  --bg-color: #f8f8f8;
  --bg-color-modal: #ffffff;
  --heading-color: #606060;
  --sidenavbar-color: #ffffff;
  --sidenavbaricon-color: #100901;
  --sidenavbarbottomborder-color: #e9e9e9;
  --type2-primary-color: #f0defc;
  --type2-secondary-color: #9900ff;
  --close-btn-modal: transparent;
}

[data-theme="dark"] {
  --primary-color: #9900ff;
  --secondary-color: #f0defc;
  --dark-theme-font-color: #f7f7f7;
  --table-display-heading-font-color: #ffffff;
  --table-display-body-font-color: #e4e1e1;
  --font-color: #e1e1ff;
  --bg-color: #161625;
  --bg-color-modal: #161625;
  --heading-color: #818cab;
  --sidenavbar-color: #100901;
  --sidenavbaricon-color: #ffffff;
  --sidenavbarbottomborder-color: #292929;
  --type2-primary-color: #9900ff;
  --type2-secondary-color: #f0defc;
  --close-btn-modal: #ffffff;
}

/* Modal */
.modal-header,
.modal-body,
.modal-footer,
.task-search-form {
  background-color: var(--bg-color-modal);
  color: var(--font-color);
}

.task-search-form {
  background-color: #ffffff !important;
  color: var(--heading-color) !important;
  /* border: 1px solid var(--dark-theme-font-color) !important; */
}
.task-search-form::placeholder {
  color: var(--heading-color) !important;
}

.modal-body form {
  background-color: var(--bg-color-modal);
  color: var(--font-color);
}

.modal-header .btn-close {
  background-color: var(--close-btn-modal) !important;
}

.cards,
.accordion-item,
.accordion-header button,
.accordion-collapse {
  background-color: var(--bg-color) !important;
}

/* Text Colors */
.heading-clr {
  color: var(--heading-color);
}
.body-clr {
  color: var(--heading-color);
}
.SM-text-clr {
  color: var(--heading-color);
}

#bootstrap-overrides input[type="text"]:focus,
#bootstrap-overrides input[type="password"]:focus,
#bootstrap-overrides input[type="email"]:focus,
#bootstrap-overrides input[type="url"]:focus,
#bootstrap-overrides input[type="checkbox"]:focus,
#bootstrap-overrides input[type="button"]:focus,
#bootstrap-overrides textarea:focus,
#bootstrap-overrides select:focus {
  /* border: none; */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0);
  outline: 0 none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

.btn-close:focus,
.btn-close:active {
  outline: none !important;
  box-shadow: none;
}

body {
  min-height: 100vh;
  background-color: var(--bg-color);
  font-family: "Rubik", sans-serif;
  margin: 0;
  font-weight: 400;
  color: var(--font-color);
}

.side-navbar {
  width: 250px;
  height: 100%;
  position: fixed;
  margin-left: -300px;
  background-color: var(--sidenavbar-color);
  transition: 0.5s;
}
.top-navbar {
  background-color: var(--sidenavbar-color);
}

.side-navbar .nav-link {
  margin-bottom: 8px;
  font-size: 13px;
  color: var(--dark-theme-font-color);
}

.side-navbar .nav-link.active {
  background-color: var(--type2-secondary-color);
  color: var(--type2-primary-color);
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: var(--bg-color);
}

.navbar-nav .nav-link:hover {
  background-color: transparent !important;
}

.board-button {
  background: linear-gradient(46deg, #2fb6f8 18.52%, #0d5ab6 94.82%) !important;
  padding: 4px 12px;
  color: #fff !important;
  margin-top: 3px;
  transition: 0.5s;
  border-radius: 2px;
}

.delivery-badge {
  background-color: rgb(235, 192, 4) !important;
}

.my-container {
  transition: 0.4s;
}

.active-nav {
  margin-left: 0;
}
.sidebar-bottom {
  padding: 0;
}

.sidebar-bottom li {
  list-style: none;
  padding: 3px;
  border-bottom: 0.5px solid var(--sidenavbarbottomborder-color);
  margin-right: 20px;
  margin-left: 20px;
  color: var(--font-color);
  font-size: 12px;
}
.sidebar-bottom li i {
  margin-right: 4px;
}

.tab-content-sec-navigation {
  font-size: 14px;
  color: #aaaaaa;
}
.tab-content-sec-navigation button {
  border: 0;
  color: #aaaaaa;
  background-color: transparent;
  padding: 0px 28px 8px 28px;
}

.tab-content-sec-navigation button:hover {
  color: #aaaaaa;
  background-color: transparent;
  opacity: 0.8;
}
.tab-content-sec-navigation .nav-item {
  position: relative;
}
.tab-content-sec-navigation .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;

  background-color: var(--type2-secondary-color);
}
.tab-content-sec-navigation .nav-link.active {
  color: var(--type2-secondary-color);
}

.active-cont {
  margin-left: 250px;
}

#menu-btn {
  background-color: var(--type2-primary-color);
  color: var(--type2-secondary-color);
  margin-left: 8px;
  padding: 4px 12px;
}
#menu-btn i {
  font-size: 25px;
}

.my-container input {
  border-radius: 2rem;
  padding: 2px 20px;
}

.spacer-80 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.progress-area {
  padding: 0px 40px 0px 20px;
  margin-bottom: 4px;
  margin-top: 36px;
}
.progress-area p {
  font-size: 10px;
  color: #a5a5a5;
  padding-top: 4px;
  letter-spacing: 0.8px;
}
.progress {
  height: 8px;
}

.tab-alt-heading {
  margin-top: 14px;
  font-size: 25px;
  font-weight: 400;
  color: var(--font-color);
  margin-bottom: 12px;
  padding-left: 16px;
}

.line-seperator {
  border: 1px solid #d4d4d4;
}

/* .client-logo{
  padding-right: 8px;
  margin-top: -4px;
}

.Client-area p{
  font-size: 20px;
}






.round-logo-area {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.round-logo-area img {
  width: initial;
  max-width: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: inherit;
} */

.Client-area {
  display: flex;
}

.client-logo {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover; /* Ensure the image covers the entire area */
  margin-right: 8px; /* Add space between logo and name */
}

.main_profiles_name {
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 42px;
  overflow: hidden; /* Hide overflow text */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--dark-theme-font-color);
  font-weight: 400;
}

.my-team-profile-photo {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  object-fit: cover; /* Ensure the image covers the entire area */
  border: 4px solid #f0f0f0;
}

/* New */

.add-task i {
  padding-left: 8px;
}

.nav i {
  padding-right: 8px;
}
.nav .badge {
  margin-left: 8px;
  position: absolute;
  margin-top: 1px;
}

.padding-12 {
  padding-right: 12px;
  padding-left: 12px;
}
.space-24 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.add-task .dropdown-item.active {
  background-color: #9900ff;
}

/* Form Starts */

form {
  padding: 20px;
  background-color: #fff;
}

h2 {
  color: #333;
}

.form-control {
  border-radius: 5px;
  margin-bottom: 10px;
}

.btn-primary {
  border-radius: 5px;
}

.form-select {
  border-radius: 5px;
}

textarea {
  border-radius: 5px;
  margin-top: 10px;
}
.form-task-name {
  border: none;
  font-size: 35px;
  color: #606060;
  font-weight: 400;
}
input.form-control.form-task-name:focus {
  color: #606060;
}
.form-control,
.btn {
  box-shadow: none !important;
  outline: none !important;
}

.form-control::placeholder {
  color: #c9c9c9;
  font-weight: 500;
  opacity: 1; /* Firefox */
}

.form-control::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: rgb(194, 194, 194);
}
.seperator-line {
  border-bottom: 1px solid var(--font-color);
  width: 100%;
  margin-bottom: 24px;
}
.seperator-line-hidden {
  margin-bottom: 24px;
}
.Project-name {
  border: none;
  font-size: 20px;
  color: #606060;
  font-weight: 400;
}

.Project-name option {
  font-size: 14px;
  margin-top: 12px;
}

.form-label {
  font-size: 12px;
  color: #cfcfcf;
}
.Brand-name {
  border: none;
  /* background-image: url(https://cubicdash.com/wp-content/uploads/2024/01/Integrity.svg); */
  font-size: 16px;
  color: #b3b3b3;
  border: 0;
  border-bottom: 2px solid #e9e9e9;
  border-radius: 0;
}

.Brand-name:focus {
  border-bottom: 2px solid #e9e9e9 !important;
}

.Brand-name option {
  background-color: #fff;
}
.due-date {
  border: none;
  border-bottom: 2px solid #e9e9e9;
  /* background-image: url(); */
  font-size: 16px;
  color: #606060;
  border-radius: 0;
}

.priority-info {
  border: none;
  background-color: #ececec;
  color: #606060;
  font-size: 16px;
}
.task-category {
  border: none;
  background-color: rgb(250, 250, 250);
  font-size: 16px;
  color: #b3b3b3;
}
.Task-submit-button {
  background-color: #424242;
  border: none;
  width: 100%;
  height: 44px;
  margin-top: -10px;
}
.Task-submit-button:hover {
  background-color: var(--secondary-color);
}
.Task-submit-button i {
  margin-right: 8px;
}
.task-details {
  font-size: 14px;
  color: #606060;
  border: none;
  border-radius: 0;
  border-top: 1px solid #ddd;
}
.task-details:focus {
  color: #606060;
  border-top: 1px solid #ddd !important;
}
.option-low {
  background-color: #ececec;
  color: #606060;
}
.option-med {
  background-color: #69ccfd;
  color: #f5f5f5;
}
.option-high {
  background-color: #fcd36d;
  color: #3d3d3d;
}
.option-urgent {
  background-color: #ff6060;
  color: #fff;
}
.addTaskModal h6 {
  font-size: 14px;
  color: #e0e0e0;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.addTaskModal h6 i {
  margin-right: 8px;
  font-size: 14px;
  color: #e0e0e0;
}

.add-brand-name {
  border: none;
  font-size: 25px;
  color: #606060;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

input.form-control.add-brand-name:focus {
  color: #606060;
  border-bottom: 1px solid #eee !important;
}

/* .view-priority-info{
border: none;
background-color: #ececec;
color: #606060;
font-size: 16px;
}

.view-priority-info:focus{
border: none;
background-color: #ececec;
color: #606060;
font-size: 16px;
} */

.view-task-category {
  border: none;
  background-color: rgb(250, 250, 250);
  font-size: 16px;
  color: #b3b3b3;
}

.view-task-category:focus {
  border: none;
  background-color: rgb(250, 250, 250);
  font-size: 16px;
  color: #b3b3b3;
}

/* .view-priority-info {
  border: none;
  font-size: 16px;
} */

.view-priority-low {
  background-color: #ececec;
  color: #606060;
  border: none;
}

.view-priority-medium {
  background-color: #69ccfd;
  color: #f5f5f5;
  border: none;
}

.view-priority-high {
  background-color: #fcd36d;
  color: #3d3d3d;
  border: none;
}

.view-priority-urgent {
  background-color: #ff6060;
  color: #fff;
  border: none;
}

.view-all-priority-info {
  pointer-events: none;
}

.view-task-category {
  pointer-events: none;
}

.view-brand-name {
  border: none;
  color: #606060;
  font-weight: 400;
  border-bottom: 1px solid #eee;
  pointer-events: none;
}

.view-due-date {
  pointer-events: none;
  border: none;
  border-bottom: 2px solid #e9e9e9;
  /* background-image: url(); */
  font-size: 16px;
  color: #606060;
  border-radius: 0;
}

.view-task-details {
  font-size: 14px;
  color: #606060;
  border: none;
  border-radius: 0;
}
.view-task-details:focus {
  color: #606060;
  border: none;
}

/* .view-priority-info.low {
  background-color: #ececec;
  color: #606060;
}

.view-priority-info.medium {
  background-color: #69CCFD;
  color: #f5f5f5;
}

.view-priority-info.high {
  background-color: #FCD36D;
  color: #3d3d3d;
}

.view-priority-info.urgent {
  background-color: #FF6060;
  color: #fff;
} */

/* Table Starts */

.padding-20 {
  padding: 20px;
}

/* .Delivery-action-buttons{
border: 0;
} */

/* *::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
width: 26px;
height: 20px;
border-radius: 13px;
background-clip: padding-box;
border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {        
box-shadow: inset 0 0 0 10px;
} */

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-track {
  width: 5px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 13px;
  border: 10px solid transparent;
  background-clip: padding-box;
  /* box-shadow: inset 0 0 0 10px; */
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:horizontal {
  box-shadow: inset 0 0 0 10px #ddd; /* Adjust the color accordingly */
}

*::-webkit-scrollbar-track:horizontal {
  background-color: #ddd; /* Adjust the color accordingly */
}

thead {
  position: sticky;
  top: -1px;
  background-color: var(--bg-color);
  z-index: 1;
  /* height: 44px; */
}

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-area {
  overflow-x: auto;
  height: 70vh;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: var(--bg-color);
  /* box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06), 0 -8px 18px rgba(0, 0, 0, 0.08); */
}

.table th,
.table td {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  background-color: var(--bg-color);
}

.table th {
  padding: 12px 8px;
  color: var(--table-display-heading-font-color);
  font-size: 12px;
  font-weight: 400;
}

.table td {
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  color: var(--table-display-body-font-color);
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.delivery-table td {
  max-width: 45ch !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.table td.task-name {
  font-size: 14px;
  color: var(--table-display-body-font-color);
}

.btn-group-vertical {
  display: flex;
  align-items: center;
}

.table button.btn {
  padding: 6px 12px;
  font-size: 12px;
}

.view-btn {
  background-color: #d2edfa;
  color: #0e81bb;
  cursor: pointer;
}

.view-btn:hover {
  background-color: #d2edfa;
  color: #0e81bb;
  opacity: 0.7;
  cursor: pointer;
}

.edit-btn {
  background-color: #fbfff5;
  color: #297c03;
}

.edit-btn:hover {
  background-color: #fbfff5;
  color: #297c03;
  opacity: 0.7;
  cursor: pointer;
}

.warning-btn {
  background-color: #fbfff5;
  color: #daa702;
}

.warning-btn:hover {
  background-color: #fbfff5;
  color: #daa702;
  opacity: 0.7;
  cursor: pointer;
}

.delete-btn {
  background-color: #f1f1f1;
  color: #f52929;
}

.delete-btn:hover {
  background-color: #f1f1f1;
  color: #f52929;
  opacity: 0.7;
  cursor: pointer;
}
.edit-btn i,
.delete-btn i {
  margin-right: 2px; /* Adjust the margin to your preference */
  vertical-align: middle;
}

.infos-pill span {
  border-radius: 200px;
  font-size: 12px;
  padding: 4px 24px;
}

.infos-low span {
  background-color: #c7c7c7;
  color: #f5f5f5;
}

.infos-med span {
  background-color: #69ccfd;
  color: #fff;
}

.infos-high span {
  background-color: #fcd36d;
  color: #3d3d3d;
}

.infos-urgent span {
  background-color: #ff6060;
  color: #fff;
}
.infos-active span {
  color: #07cc42; /* Green color for "Active" status */
  font-size: 12px;
}

.infos-closed span {
  color: #f52929; /* Red color for "Closed" status */
  font-size: 12px;
}

.closed-infos-pill span {
  border-radius: 200px;
  font-size: 12px;
  padding: 4px 24px;
}

.closed-infos-low span {
  background-color: #c7c7c7;
  color: #f5f5f5;
}

.closed-infos-med span {
  background-color: #69ccfd;
  color: #fff;
}

.closed-infos-high span {
  background-color: #fcd36d;
  color: #3d3d3d;
}

.closed-infos-urgent span {
  background-color: #ff6060;
  color: #fff;
}

.closed-infos-active span {
  color: #07cc42; /* Green color for "Active" status */
  font-size: 12px;
}

.closed-infos-closed span {
  color: #f52929; /* Red color for "Closed" status */
  font-size: 12px;
}

.closed-infos-newDelivery span {
  color: #daa702; /* Custom color for "New Delivery" status */
  font-size: 12px;
}

.closed-infos-revision span {
  color: #740202; /* Custom color for "Revision" status */
  font-size: 12px;
}

.closed-infos-accepted span {
  color: #005014; /* Custom color for "Accepted" status */
  font-size: 12px;
}

.rating-cell i {
  color: #ffbd13;
}

.infos-status i {
  font-size: 10px;
}

.closed-infos-status i {
  font-size: 10px;
}
td i {
  margin-right: 6px;
  opacity: 0.7;
}

.sidebar.nav-link {
  margin-top: 12px;
  margin-bottom: 12px;
}

.Delivery-Link a {
  color: rgb(4, 173, 224);
  font-size: 14px;
}
.Delivery-Link a i {
  margin-left: 4px;
  margin-top: 1px;
}

.edit-revision-btn {
  background-color: #ff5858;
  color: #ffffff;
  font-size: 12px;
}

.edit-feedback-btn {
  background-color: #46a05c;
  color: #ffffff;
  font-size: 12px;
}
.edit-revision-btn:hover {
  background-color: #740202;
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
}

.edit-feedback-btn:hover {
  background-color: #005014;
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
}

.delete-delivery-btn {
  background-color: #f1f1f1;
  color: #f52929;
}

.delete-delivery-btn:hover {
  background-color: #f1f1f1;
  color: #f52929;
  opacity: 0.7;
}

.delivery-infos-newDelivery span {
  color: #daa702;
  font-size: 12px;
}
.delivery-infos-revision span {
  color: #ff5858;
  font-size: 12px;
}
.delivery-infos-accepted span {
  color: #46a05c;
  font-size: 12px;
}

.table-brand-form {
  background-color: transparent;
  padding: 0;
}
.table-brand-form input {
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid #afafaf;
  background: transparent;
}
.table-brand-form input:focus {
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid #afafaf !important;
  background: transparent;
}

.task-search-form {
  height: 40px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.03) !important;
  border-radius: 4px !important;
  background-color: #fff;
}

.task-search-form:focus {
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.Limit-reached {
  font-size: 12px;
  text-decoration: none;
  color: #fa3c3c;
}

.Limit-reached:hover {
  color: #fa3c3c;
  opacity: 0.6;
}

textarea#deliveryFeedbackView {
  background: #fff9e8;
  font-size: 14px;
  font-style: italic;
  padding: 16px;
}

/*Theme switch css */

.theme-switch-wrapper {
  display: flex;
  align-items: center;
}
.theme-switch-wrapper em {
  margin-left: 10px;
  font-size: 1rem;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #222222;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* My team */

.cards {
  border-radius: 4px;
  overflow: hidden;
  border: 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.07);
  transition: all 0.15s ease;
  background-color: #fff;
  margin-bottom: 28px;
}

.cards:hover {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1), 0 10px 8px rgba(0, 0, 0, 0.015);
}

.card-border-purple {
  border-left: 8px solid var(--secondary-color);
}

.card-header {
  border-bottom: 1px solid #eee;
  padding: 8px 8px;
  background-color: transparent;
  margin-left: 8px;
  margin-right: 8px;
}

.card-header .designation-main {
  font-size: 14px;
}

.card-header h5 {
  font-size: 12px;
  color: #afafaf;
  font-weight: 400;
  padding: 4px 4px;
  margin: 0;
  margin-top: 2px;
}

/* .card-header h5 span{
color: #05e235;
} */

.team-online {
  color: #05e235;
}

.team-offline {
  color: #c7c7c7;
}

.card-header h5 span i {
  margin-right: 4px;
  font-size: 8px;
  position: relative;
  top: -1px;
}

/* .infos-online span {
color: #05e235;
}

.infos-offline span {
color: #fc3b19;
} */

.Team-profile-name {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #606060;
  line-height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Team-profile-designation {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #adb9ff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.About-profile-text {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 400;
  /* padding: 0; */
  margin: 0;
  color: #b4b4b4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-cta {
  border-top: 2px dashed #f4e4ff;
  width: 96%;
  margin-left: 2%;
}
.extra-small-btn {
  font-size: 14px;
  line-height: 16px;
}
.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed) {
  background-color: #fff;
}

.accordion-item {
  border: 0;
}

button.accordion-button.collapsed {
  color: #dca7ff;
  font-size: 14px;
}

.accordion-button:not(.collapsed) {
  color: #dca7ff;
  font-size: 14px;
}

button.accordion-button {
  color: #dca7ff;
  font-size: 14px;
}

.accordion-body h3 {
  padding: 0;
  padding-bottom: 4px;
  margin: 0;
  font-size: 16px;
  color: #606060;
  font-weight: 400;
}

.accordion-body p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #979797;
  font-weight: 400;
}

.accordion-body {
  border-top: 1px dashed #ddd;
  padding: 12px 20px;
}

.accordion-button {
  padding: 12px 8px;
}

/* Subscriptions */

.Subscription-card .card-header {
  border-bottom: 0;
  padding: 8px 8px 0px 8px;
  background-color: transparent;
}

.Subscription-card .card-body {
  padding: 0px 16px 16px 16px;
}

.Subscription-card .card-header h5 {
  padding: 0;
}

.plan-detail-name {
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-weight: 400;
  color: #606060;
  line-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.plan-name {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #727272;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.plan-name span {
  color: var(--secondary-color);
}

.renewal-date {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 400;
  /* padding: 0; */
  margin: 0;
  color: #b4b4b4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.manage-subscription-link {
  padding-top: 4px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #7a7a7a;
}
.manage-subscription-link i {
  margin-right: 4px;
  /* font-size: 16px; */
}
.manage-subscription-link:hover {
  color: #b4b4b4;
  opacity: 0.8;
}

.Subscription-card .card-cta {
  border-top: 1px solid #dddddd;
  width: 96%;
  margin-left: 2%;
}
.new-subsciption-btn {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: transparent;
}
.new-subsciption-btn:hover {
  background-color: var(--secondary-color);
  opacity: 0.8;
  color: var(--primary-color);
}
.new-subsciption-btn a {
  text-decoration: none;
  color: var(--primary-color);
}

a#viewBillingLink {
  text-decoration: none;
  color: var(--secondary-color);
}

.bill-link-button:hover a#viewBillingLink {
  text-decoration: none;
  color: #fff;
}

a#viewBillingLink:hover {
  text-decoration: none;
  color: #fff;
}

/* Accept Delivery Form */

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  font-size: 2rem;
  color: #ffbd13;
  margin-bottom: 16px;
  margin-top: 16px;
}
.rating i {
  font-size: 36px;
}

.rating .star {
  cursor: pointer;
}

.rating .star.active {
  opacity: 0;
  animation: animate 0.5s calc(var(--i) * 0.1s) ease-in-out forwards;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.rating .star:hover {
  transform: scale(1.1);
}

.da-form {
  text-align: center;
}
.da-form h3 {
  font-size: 25px;
  color: #606060;
  font-weight: 400;
}
.da-form textarea {
  background-color: #fafafa;
  border: 1px solid #eee;
}
.da-form textarea:focus {
  background-color: #fafafa;
  border: 1px solid #eee !important;
}
.da-modal .modal-header {
  border: none;
  border-bottom: 1px dashed #ddd;
  margin: 0px 24px 0px 24px;
}
#rating-message {
  font-size: 16px;
  color: #cccccc;
}

/* Rivision Form */

.revision-task-name {
  border: none;
  font-size: 25px;
  color: #606060;
  font-weight: 400;
}

.revision-task-name-label {
  opacity: 0.3 !important;
}

/* Delete Task */

.deleteTaskModal h5 {
  font-weight: 400;
  color: #4d4d4d;
  font-size: 20px;
}

.delete-alert-area {
  background-color: #ffe3e3;
  font-size: 14px;
  color: #fd0000;
  border-radius: 4px;
}

.deleteTaskModal .modal-header h5 {
  font-size: 16px;
  color: #afafaf;
}

label.cabinet {
  margin-top: 90px;
  display: block;
  cursor: pointer;
  position: relative;
}

label.cabinet input.file {
  position: absolute;
  height: 100%;
  width: auto;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  margin-top: -30px;
}

#upload-demo {
  width: 250px;
  height: 250px;
  padding-bottom: 25px;
}

figure figcaption {
  position: absolute;
  bottom: -17px;
  color: #fff;
  width: 100%;
  padding-left: 55px;
  padding-bottom: 60px;
  text-shadow: 0 0 10px #000;
}

figure img {
  width: 140px !important;
  height: 125px !important;
  border-radius: 20px !important;
}
.rounded-circle {
  border-radius: 2% !important;
}

button.carousel_main_button.active {
  width: 40px !important;
  height: 6px !important;
  border: 1px solid transparent;
  border-radius: 6px;
}

button.carousel_main_button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 200px;
  background-color: #9900ff !important;
  pointer-events: none;
}

.carousel-indicators [data-bs-target] {
  border-top: none;
  border-bottom: none;
}

.register-display-name {
  border: none !important;
  font-size: 25px;
  color: #606060;
  font-weight: 400;
  padding-left: 12px;
  padding-right: 12px;
}

.register-primary-btn {
  width: 150px;
  background-color: #9900ff;
}
.register-submit-btn {
  width: 250px;
  background-color: #9900ff;
}

.register_tilte_text {
  color: #606060;
}
.para_text_color {
  color: #b3b3b3;
}
.register_tilte_text_color {
  color: #283689;
}

.register-display-bio {
  border: none !important;
  font-size: 25px;
}
.pr_phto_text {
  font-size: 16px;
  color: #b9b9b9;
  font-weight: 400;
}

.profile-photo-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.Upld_btn {
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 400;
}

.profile-photo-upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.register_final_image {
  width: 200px;
}

.profile-display-name {
  border: none !important;
  font-size: 22px;
  color: #606060;
  font-weight: 400;
}

.profile-about-bio {
  border: none !important;
  font-size: 16px;
  padding-left: 12px;
}

button.btn.btn-secondary.profile-edit-btn:hover {
  background-color: #9900ff !important;
  border: 1px solid #9900ff;
}

.carousel-control-prev-icon {
  background-image: url(https://jcs.jordieferoz.com/wp-content/uploads/2024/04/arrow-left.svg);
}

.carousel-control-next-icon {
  background-image: url(https://jcs.jordieferoz.com/wp-content/uploads/2024/04/arrow-right.svg);
}

.get-str-txt {
  width: 70%;
  margin-left: 15%;
}
.get-str h3 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  color: var(--secondary-color);
  font-weight: 400;
}

.get-str p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--font-color);
  font-weight: 400;
  margin-top: 12px;
}

.theme-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  margin: 0 auto;
}

.theme-switch-wrapper span {
  font-size: 14px;
  color: var(--font-color);
}

.theme-switch {
  position: relative;
  display: inline-block;
  height: 34px;
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input#deliveryTaskID {
  text-align: center;
}

.Delivery-main-task-id i {
  margin-left: 4px;
}

.copy-notification {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-10%, -50%);
  padding: 8px 16px;
  border-radius: 20px;
  background-color: rgba(
    0,
    123,
    255,
    0.8
  ); /* Adjust the color and opacity as needed */
  color: #fff;
  font-size: 14px;
  z-index: 9999;
}

.form-footer-info {
  padding: 12px;
  background-color: #dcf2fd;
  font-size: 14px;
  color: #0674f3;
  border-radius: 8px;
  position: absolute;
  left: 28px;
}

.form-footer-info i {
  margin-right: 4px;
  font-size: 16px;
  color: #0674f3;
}

.form-footer-info2 {
  padding: 12px;
  background-color: #dcf2fd;
  font-size: 14px;
  color: #0674f3;
  border-radius: 8px;
  width: auto;
}

.form-footer-info2 i {
  margin-right: 4px;
  font-size: 16px;
  color: #0674f3;
}

.textLink {
  text-align: center;
  padding: 12px 60px 12px 30px;
  height: 45px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #494949;
  border-radius: 25px;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.textLink:focus {
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.task-locked-area h3 {
  font-size: 24px;
}
.task-locked-area h3 span {
  color: #fc391f;
}
.task-locked-area p {
  font-size: 16px;
}

.task-locked-area h5 {
  font-size: 14px;
  opacity: 0.5;
  font-weight: 400;
}

.mainModalHeader {
  font-size: 16px;
  color: #ddd;
  font-weight: 400;
}
.mainModalHeader i {
  margin-right: 4px;
}

.view-plan-details {
  text-decoration: none;
}
.view-plan-details i {
  margin-left: 2px;
}

.no-border {
  border: none !important;
}

.no-pointer {
  pointer-events: none;
}

.error_message {
  padding-left: 12px;
  color: #f08989;
  font-size: 14px;
}

.time-btns button {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  border-radius: 4px;
}
.time-btns button:disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}
.in-btn {
  padding: 12px 40px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #1ebb4a;
  color: #fff;
  border: none;
}
.out-btn {
  padding: 12px 40px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: #ff8585;
  color: #fff;
  border: none;
}

#messagex {
  font-size: 12px;
  line-height: 30px;
  width: 286px;
  height: 30px;
  background-color: #dfe7fd;
  text-align: center;
  margin: auto;
  margin-top: 16px;
  color: var(--font-color);
}

.progress-bar-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.progress-bar-segment {
  height: 100%;
}

.bg-color-25 {
  background-color: #ff0000; /* Red */
}

.bg-color-50 {
  background-color: #ffa500; /* Orange */
}

.bg-color-75 {
  background-color: #ffff00; /* Yellow */
}

.bg-color-100 {
  background-color: #008000; /* Green */
}

.link-color {
  color: #126ddf;
}

.font-sm {
  font-size: 12px;
}

.ql-toolbar {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.ql-container {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 200px;
}

.fullscreen-mode .ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: calc(100vh - 200px);
}

.fullscreen-mode {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: white;
  padding: 20px;
  overflow: auto;
}

.paragraph_regular {
  font-size: 16px;
  font-weight: normal;
  color: #606060;
}
.search.task-search-form::placeholder {
  color: #dddddf !important;
  font-size: 12px;
}
.footer-text {
  font-size: 10px;
  line-height: 12px;
}
