.heading1 {
  @apply text-5xl;
}

.heading2 {
  @apply text-[45px];
}

.heading3 {
  @apply text-3xl;
}

.heading4 {
  @apply text-2xl;
}

.heading5 {
  @apply text-xl;
}

.paragraph {
  @apply text-base;
}

.label_regular {
  @apply text-sm;
}

.label_small_regular {
  @apply text-xs;
}
