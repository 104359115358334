.toast-success {
  color: green;
}

.toast-error {
  color: red;
}

.toast-background-white{
  background-color: white;
}
